const scheduleHelpClientWhitelist = [
  // Aging & In-Home Services
  '89ed6753-2dba-42b1-9999-a3816b1f6318',
  // Aiken County
  '60840f2c-007c-43e9-8d1f-08e774ab2db9',
  // BRC - ADMINISTRATION
  'a2b5f37c-49b6-4e31-a017-fca394476710',
  // City of Baltimore Employees Retirement Sy
  '9d5dc3db-7c11-42cf-b548-2d91b5bebc0e',
  // Cameron Memorial Community Hospital
  'ee68f1f6-4411-40cc-a1aa-e3bb1573bb29',
  // Center for Orthopedic & Research Excellence, Inc.
  'a806288e-8281-45a1-895e-4840e4a78439',
  // City Of New Haven
  'd997b73e-9835-484b-a862-4ea30a4691fa',
  // Colson Group
  'ae4a936a-6a2b-4660-aa48-d69788945cbd',
  // Columbia Housing Authority
  '77dfbe9f-f197-4398-b5e9-38852ab505e2',
  // Community State Bank in Avilla
  '6764d58c-473f-4f2c-9f99-38fa05119bd5',
  // First Bank Of Berne
  '1aedde3e-5e40-46b7-9ee5-bf548e93a767',
  // Fusion Education Group
  'dd63776a-95b5-4c63-812c-202bee408817',
  // Innovia Medical
  '3518106a-53f3-4742-a811-327a539bc6cd',
  // John S. James Co.
  'd3bb2dee-aaf8-4e66-a855-3ea6ff0fc16a',
  // NHE, Inc.
  '5af291c5-b70a-4bf3-ba7e-c446c802f1ba',
  // Noble Supply & Logistics
  'e2d40d27-fe1f-4e37-90b7-7c29d4033a58',
  // Novae LLC
  '20794aae-e0ca-4e25-a1ce-07a81b06be82',
  // O'Daniel Automotive Group
  'fd4eab80-8203-4ecc-91dd-20aa8ec3f431',
  // Scientific Research Corporation
  'ee397f59-d5c1-422c-aa89-24a2f74b5c82',
  // Shepherd Hardware Products, LLC,
  'fa58a23a-17f0-441f-8156-9b8fb1e9a740',
  // Southern Michigan Bank & Trust
  '1be64357-1489-4a5c-ad13-dcd41f4e77cd',
  // SupplyLogic, LLC
  '0ccef2cb-c013-499f-af49-7ab3b306e0a7',
  // The Greenville Housing Authority
  '4776362a-898c-4c81-bc01-7e437b4cdbf7',
  // Tippmann Group
  'd508be6b-575c-4ca6-8c1d-33e72ee7e26d',
  // Willson, Jones, Carter & Baxley, PA
  'da9ead47-cd10-4b76-9915-17a21865d862',
  // Bohl Companies
  '6de3c22b-c99c-4148-a73c-b6b974993370',
  // DOXA Insurance Holdings LLC
  'c90af223-0c5c-43d7-8682-4d3376d101ce',
  // Deister
  'e2cb8a57-ceef-4e45-9f88-68a9861c6c28',
];

module.exports = scheduleHelpClientWhitelist;
