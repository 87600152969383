import { getToken } from './api';
import { jwtDecode } from './utils/jwt';
import { addDays } from 'date-fns';

export const FEATURE_LIST = {
  ONBOARDING_ROADMAP: 'onboarding_roadmap',
  SECURITY_POSTURE: 'security_posture',
  DNS_ACTIVITY: 'dns_activity',
  VULNERABILITIES: 'vulnerabilities',
  VULNERABILITIES_BASIC: 'vulnerabilities_basic',
  DARK_WEB_MONITORING: 'dark_web_monitoring',
  SEATS: 'seats',
  PATCHING_V2: 'patching_v2',
  UTILIZATION: 'utilization',
  CONFIGURATION_CHANGES: 'configuration_changes',
  VPN_ACTIVITY: 'vpn_activity',
  NETWORK_THREATS: 'network_threats',
  FAILED_LOGINS: 'failed_logins',
  DASHBOARD: 'dashboard',
  DEVICE_LIFE_CYCLE: 'device_life_cycle',
  SELF_SERVICE: 'self_service',
  USER_MANAGEMENT: 'user_management',
  TICKETS: 'tickets',
  TECH_ROADMAP: 'technology_roadmap',
  SECURITY_AWARENESS_TRAINING: 'security_awareness_training',
  INTERNET_UTILIZATION: 'internet_utilization',
  ENDPOINT_PROTECTION: 'endpoint_protection',
  ACTIVE_DIRECTORY: 'active_directory',
  BACKUPS: 'backups',
  DEVICES_OVERVIEW: 'devices_overview',
  DEVICES_LIST: 'devices_list',
  MY_TICKETS: 'my_tickets',
  SECURITY_INCIDENTS: 'security_incidents',
  SECURITY_REMEDIATIONS: 'security_remediations',
  REPORTS: 'reports',
  CLIENT_SETTINGS: 'client_settings',
  VIRTUAL_ASSISTANT: 'virtual_assistant',
  SUPPORT_OVERVIEW: 'support_overview',
};

export const SECTIONS = {
  SECURITY: [
    FEATURE_LIST.SECURITY_INCIDENTS,
    FEATURE_LIST.SECURITY_REMEDIATIONS,
  ],
  DEVICES: [
    FEATURE_LIST.DEVICES_OVERVIEW,
    FEATURE_LIST.DEVICES_LIST,
    FEATURE_LIST.ENDPOINT_PROTECTION,
    FEATURE_LIST.SEATS,
    FEATURE_LIST.PATCHING_V2,
    FEATURE_LIST.VULNERABILITIES,
    FEATURE_LIST.VULNERABILITIES_BASIC,
    FEATURE_LIST.DNS_ACTIVITY,
    FEATURE_LIST.UTILIZATION,
    FEATURE_LIST.DEVICE_LIFE_CYCLE,
    FEATURE_LIST.BACKUPS,
  ],
  NETWORK_EVENTS: [
    FEATURE_LIST.NETWORK_THREATS,
    FEATURE_LIST.VPN_ACTIVITY,
    FEATURE_LIST.CONFIGURATION_CHANGES,
    FEATURE_LIST.FAILED_LOGINS,
    FEATURE_LIST.INTERNET_UTILIZATION,
  ],
  SELF_SERVICE: [FEATURE_LIST.SELF_SERVICE],
  MY_ORGANIZATION: [
    FEATURE_LIST.SUPPORT_OVERVIEW,
    FEATURE_LIST.DARK_WEB_MONITORING,
    FEATURE_LIST.TICKETS,
    FEATURE_LIST.TECH_ROADMAP,
    FEATURE_LIST.SECURITY_POSTURE,
    FEATURE_LIST.SECURITY_AWARENESS_TRAINING,
    FEATURE_LIST.ACTIVE_DIRECTORY,
    FEATURE_LIST.REPORTS,
  ],
  ADMIN: [FEATURE_LIST.USER_MANAGEMENT, FEATURE_LIST.CLIENT_SETTINGS],
};

/**
 * This list maps each widget to its corresponding features.
 * Used to hide widgets if a user doesn't have access to the feature the widget uses.
 */
export const WIDGETS = {
  SEATS: [FEATURE_LIST.SEATS],
  PATCHING_V2: [FEATURE_LIST.PATCHING_V2],
  THREATS_BLOCKED_COUNT: [FEATURE_LIST.NETWORK_THREATS],
  SUPPORT_TICKETS: [FEATURE_LIST.TICKETS],
  OVERALL_CSAT: [FEATURE_LIST.TICKETS],
  DEVICE_REFRESHES: [FEATURE_LIST.DEVICE_LIFE_CYCLE],
  SERVER_UTILIZATION: [FEATURE_LIST.UTILIZATION],
  WORKSTATION_UTILIZATION: [FEATURE_LIST.UTILIZATION],
  THREATS_BLOCKED_MAP: [FEATURE_LIST.NETWORK_THREATS],
  INTERNET_UTILIZATION: [FEATURE_LIST.INTERNET_UTILIZATION],
  MALWARE: [FEATURE_LIST.MALWARE_PROTECTION],
  DARK_WEB_MONITORING: [FEATURE_LIST.DARK_WEB_MONITORING],
  VULNERABILITIES: [FEATURE_LIST.VULNERABILITIES],
  DNS_ACTIVITY: [FEATURE_LIST.DNS_ACTIVITY],
  ENDPOINT_PROTECTION: [FEATURE_LIST.ENDPOINT_PROTECTION],
  SECURITY_INCIDENTS: [FEATURE_LIST.SECURITY_INCIDENTS],
  SECURITY_REMEDIATIONS: [FEATURE_LIST.SECURITY_REMEDIATIONS],
  SECURITY_AWARENESS_TRAINING: [FEATURE_LIST.SECURITY_AWARENESS_TRAINING],
  WORKSTATION_COUNT: [FEATURE_LIST.DEVICES_LIST],
  ONBOARDING_ROADMAP: [FEATURE_LIST.ONBOARDING_ROADMAP],
};

const decodedJwt = () => {
  const jwt = getToken();

  let decodedToken;
  if (jwt) {
    decodedToken = jwtDecode(jwt);
  }
  return decodedToken || {};
};

export const activeFeatures = () => decodedJwt().active_features || [];
export const upsellFeatures = () => decodedJwt().upsell_features || [];

export const isAuthorized = feature => {
  return activeFeatures().includes(feature);
};
//export const isAuthorized = (feature) => activeFeatures().includes(feature);
/* TODO: re-enable this when token features moves to an object format
  activeFeatures().some(af => af.name === feature && af.is_upsell === false);
  */

export const isUpsell = feature => upsellFeatures().includes(feature);
/* TODO: re-enable this when token features moves to an object format
  activeFeatures().some(af => af.name === feature && af.is_upsell);
  */

const today = new Date();
const getThirtyDaysLater = date => addDays(date, 30);
// To use this, pass it the start date of the 30 days. so if its new starting today, pass it today's date.
const checkReleaseDate = date => getThirtyDaysLater(date) >= today;
// To use this one, just pass it the exact date you want it to return false.
const checkExactDate = date => {
  const result = new Date(date) > today;
  return result;
};
export const isNew = feature => {
  // manually setting this for now
  let result = false;
  switch (feature) {
    case 'support_overview':
      result = checkReleaseDate('2021-09-30');
      break;
    case 'devices_overview':
      result = checkReleaseDate('2021-09-30');
      break;
    case 'security_remediations':
      result = checkReleaseDate('2022-03-04');
      break;
    case 'security_awareness_training':
      result = checkExactDate('2022-06-30'); // this is set to expire at the end of june
      break;
    default:
  }
  return result;
};
/* TODO: re-enable this when token features moves to an object format
  activeFeatures().some(af => af.name === feature && af.is_new);
  */

/**
 * Accepts a feature, and returns bool based on if the user can see the feature either
 * because it is an upsell feature, or because it is an active feature
 * @param {String} feature - the name of a feature
 * @returns {Boolean} user is or isn't allowed to see feature
 */

export const canSeeFeature = feature =>
  isAuthorized(feature) || isUpsell(feature);

/**
 * Accepts an array of features and returns true if the current user has access to at least one of the features.
 * @param {String[]} features the features in the section we're checking
 * @returns {Boolean} whether or not the current user can access at least one of the features passed
 */

export const canSeeSection = features => {
  let isAuthorizedForSection = false;

  if (!features) {
    return false;
  }

  features.forEach(feature => {
    if (canSeeFeature(feature)) {
      isAuthorizedForSection = true;
    }
  });

  return isAuthorizedForSection;
};

/**
 * This is used by the dashboard to determine if it should show widgets or not.
 * currently it should NOT show "upsell" features. When it does, we can probably
 * just re-use the `canSeeSection` in place of this.
 * @param {String[]} features the features in the section we're checking
 * @returns {Boolean} whether or not the current user can access at least one of the features passed
 */

export const canSeeDashboardWidget = features => {
  let isAuthorizedForSection = false;

  if (!features) {
    return false;
  }

  features.forEach(feature => {
    if (isAuthorized(feature)) {
      isAuthorizedForSection = true;
    }
  });

  return isAuthorizedForSection;
};
