const scheduleHelpUserWhitelist = [
  // Peter
  'b08f7f71-69a8-4115-84c2-c9d36e5d1b12',
  // (Wes Dekoninck - wdekoninck@corsicatech.com)
  '10c62672-8075-459e-84dc-0a7112b1dc17',
  // (Jamie Perryman - jperryman@corsicatech.com)
  '4de450e2-55b8-4fce-b083-ef5337d3c453',
  // Josh Daniel's - the main test user on mock-api when logging in with ad_user@testing.com
  '1234asdf1234asdf',
  // (Robert Carmona - rcarmona@corsicatech.com)
  '6c6a8030-661a-40ad-bfd5-d7a6e095238b',

  // FFSBA
  '02bfec26-d5a6-450d-a365-402fa5aa2a33', // Amy Ross aross@ffsbangola.com
  '2b5250e3-11c0-418a-857a-9bc8bf8dbec5', // Rebecca Walker – rwalker@ffsbangola.com
  'acb9920c-d96f-419b-9b4d-f4c0d4d29cf8', // Scott Gruner – sgruner@ffsbangola.com
  '39db1b10-1b58-4bca-a4b8-b1ecca666ac7', // Max DiCicco – mdicicco@ffsbangola.com
  'e9ea072c-d870-4217-b3d3-6fcb68eedab7', // Angela Crone – acrone@ffsbangola.com
  '55271acf-cc79-4827-bf28-ea57ef1edc0f', // Ashley Boone – aboone@ffsbangola.com

  // Client Beta Users from Garrett State Bank
  'a4c6590b-0805-4387-b985-334fe5c8e05f', // Kraig Kelham - kraigk@garrettstatebank.com
  '80822efc-b320-4636-92a8-7a6e54e4300c', // Nathan Perkins - nperkins@garrettstatebank.com
];

module.exports = scheduleHelpUserWhitelist;
