import React from 'react';

import withQueryParams from '../queryParamHOC';

import { environment } from '../environment';
import { Clients, ErrorHandler, LifeCycle, setClientIdAPI } from '../api';
import { FormSelect } from './';
import { AppContext } from '../AppContext';
import { Button } from 'semantic-ui-react';

import './CustomerSelect.scss';

const CORSICA_USER_ID = environment.current.corsicaCustomerId;

const sortCustomers = (a, b) => {
  if (a.text.toLowerCase() !== b.text.toLowerCase()) {
    return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
  } else {
    return 0;
  }
};

class CustomerSelect extends React.Component {
  _isMounted = false;
  _userCustomerId = null;
  _isMultiAccountUser = false;
  _clientName = null;
  _isCorsicaUser = false;
  _isMultiOrCorsica = false;

  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      dropdownOptions: [],
      isLoading: true,
      placeholderLength: null,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this._fetchUserProfile();
    this._fetchClient();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetchUserProfile = async () => {
    let _userProfile = this.props.userProfile;

    // Check if profile is set, if not try to get it
    if (!this.props.userProfile) {
      _userProfile = await this.props.getUserProfile();
    }

    this._userCustomerId = _userProfile?.client_id;
    this._isCorsicaUser = this._userCustomerId === CORSICA_USER_ID;
    this._isMultiAccountUser = _userProfile?.multi_account_access;
    this._isMultiOrCorsica = this._isMultiAccountUser || this._isCorsicaUser;
    this._clientName = _userProfile?.client_name;
  };

  loadCustomers = () => {
    // if a symplexity user is logged in, fetch all customers for the dropdown

    if (this._isMultiOrCorsica) {
      this._fetchAllClients();
    } else {
      // else, if a customer is logged in, just load their data
      this._fetchSingleClient();
    }
  };

  /* get the client name, and set it on the user profile */
  setProfileFromClientData = data => {
    if (!data) {
      return;
    }
    const profile = this.props.userProfile;

    let thisClient = data;

    if (Array.isArray(thisClient)) {
      thisClient = thisClient.find(client => client?.id === profile?.client_id);
    }
    const clientName = thisClient?.name;
    if (clientName) {
      this.props.setUserProfile({
        ...(profile || {}),
        client_name: clientName,
      });
    }
  };

  _fetchClient = async () => {
    // first of all, if there is no user client id yet, this is a stale session, kick them out
    if (!this._userCustomerId) {
      this.props.logout();
      return;
    }

    // grab the client id from the url param
    const customerIdParam = this.props.queryParams.customer;

    // if there is a client id in the url AND the user is corsica user, then try to load that, otherwise load their base user
    const clientIDToFetch =
      customerIdParam && this._userCustomerId === CORSICA_USER_ID
        ? customerIdParam
        : this._userCustomerId;
    let res;
    try {
      res = await Clients.getById(clientIDToFetch);
    } catch (err) {
      ErrorHandler.error(err);
    }
    // if we failed to get the client from the url, just load their base client
    try {
      res = res || (await Clients.getById(this._userCustomerId));
    } catch (err) {
      ErrorHandler.error(err);
      if (err.status === 500) {
        // wrong user profile, clear and start over
        this.props.logout();
      }
    }
    if (res?.body) {
      this.handleCustomerSelect(res.body);
      if (this._isMultiOrCorsica) {
        this._fetchClientListForDropdown();
      }
      this.setProfileFromClientData(res.body);
    } else {
      // kick them out, and clear token
      this.props.logout();
    }
  };

  _fetchSelectedClient = async clientId => {
    let res;
    try {
      res = await Clients.getById(clientId);
    } catch (err) {
      ErrorHandler.error(err);
    }
    if (res?.body) {
      this.handleCustomerSelect(res.body, true);
    }
  };

  _fetchClientListForDropdown = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Clients.get();
    } catch (err) {
      if (err.status === 500) {
        // wrong user profile, clear and start over
        this.props.logout();
      }
      ErrorHandler.error(err);
    }

    if (res?.body?.length) {
      this.populateDropdown(res.body);
    }
    this.safeSetState({
      isLoading: false,
    });
  };

  populateDropdown(customers) {
    const dropdownOptions = customers
      .map(c => {
        return (
          c.name &&
          !c.parent_id && // filter out anything with a parent id
          c.id && {
            text: c.name,
            value: c.id,
            key: c.id,
          }
        );
      })
      // filter out the nulls
      .filter(f => !!f)
      // sort them
      .sort(sortCustomers);

    this.safeSetState({
      customers,
      dropdownOptions,
    });
  }

  _fetchCustomerEOLDevices = async () => {
    try {
      return await LifeCycle.getEOLDevices();
    } catch (err) {
      ErrorHandler.error(err);
    }
  };

  componentDidUpdate() {
    // if there is no customer query param in the url, but there is a selected
    // customer, update the url
    if (!this.props.queryParams.customer && this.props.selectedCustomer.id) {
      this.props.setQueryParams({ customer: this.props.selectedCustomer.id });
    }
  }

  handleCustomerSelect = async (customer, shouldResetFilters = false) => {
    setClientIdAPI(customer.id);
    this.setState({
      placeholderLength:
        customer.name.length < 30
          ? customer.name.length * 9
          : customer.name.length * 8,
    });
    this.props.setQueryParams({ customer: customer.id });
    this.props.onCustomerChange(customer);
    // turn on notification if they have EOL and are NOT a role=user
    if (this.props.userProfile?.role !== 'user') {
      const eolDevices = await this._fetchCustomerEOLDevices();
      if (eolDevices?.body?.data?.length > 0) {
        const eolWindows = eolDevices.body.data.find(
          eol => eol.operating_system === 'Windows 7'
        );
        const eolServer = eolDevices.body.data.find(
          eol => eol.operating_system === 'Windows Server 2008 R2'
        );
        const eolServerCount = eolServer?.device_count || 0;
        const eolWindowsCount = eolWindows?.device_count || 0;
        if (eolServerCount || eolWindowsCount) {
          this.props.showNotification({
            serverCount: eolServerCount,
            windowsCount: eolWindowsCount,
          });
        }
      }
    }
  };

  handleCustomerDropdownChange = (e, customerId) => {
    // make sure the customer ID selected matches a customer ID in the list.
    const match = this.state.customers.find(cust => cust.id === customerId);

    // If it's a keydown and the key is enter, go ahead and load a new customer
    if (e.type === 'keydown') {
      if (e.key === 'Enter') {
        if (match) {
          this._fetchSelectedClient(customerId);
        }
      }
      // And for anything else, load a new customer
    } else {
      if (match) {
        this._fetchSelectedClient(customerId);
      }
    }
  };

  handleToggleImitateClient = async () => {
    const { selectedCustomer, imitateClientId } = this.props;
    this.props.setImitateClientId(imitateClientId ? null : selectedCustomer.id);
  };

  render() {
    const { selectedCustomer, imitateClientId } = this.props;
    const { dropdownOptions, isLoading } = this.state;

    return (
      <div className="CustomerSelect">
        <ClientSelector
          isImitatingClient={!!imitateClientId}
          isCorsicaUser={this._isCorsicaUser}
          isMultiLocationUser={this._isMultiAccountUser}
          dropdownOptions={dropdownOptions}
          selectedCustomer={selectedCustomer}
          isLoading={isLoading}
          handleCustomerDropdownChange={this.handleCustomerDropdownChange}
        />
        {this._userCustomerId === CORSICA_USER_ID && (
          <Button
            primary
            inverted
            onClick={this.handleToggleImitateClient}
            className="btn-imitate-client">
            {imitateClientId ? 'Exit Client View' : 'View as Client'}
          </Button>
        )}
      </div>
    );
  }
}

const QPCustomerSelect = withQueryParams({
  stripUnknownKeys: false,
  keys: {
    customer: {
      default: undefined,
      validate: () => true,
    },
  },
})(CustomerSelect);

const WrappedCustomerSelect = props => (
  <AppContext.Consumer>
    {({
      getUserProfile,
      selectedCustomer,
      userProfile,
      showNotification,
      setCustomer,
      setProfile,
      setImitateClientId,
      imitateClientId,
      logout,
    }) => (
      <QPCustomerSelect
        {...props}
        logout={logout}
        setImitateClientId={setImitateClientId}
        imitateClientId={imitateClientId}
        getUserProfile={getUserProfile}
        setUserProfile={setProfile}
        selectedCustomer={selectedCustomer}
        userProfile={userProfile}
        onCustomerChange={setCustomer}
        showNotification={showNotification}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedCustomerSelect;

const ClientSelector = ({
  dropdownOptions,
  selectedCustomer,
  isLoading,
  handleCustomerDropdownChange,
  isCorsicaUser,
  isMultiLocationUser,
  isImitatingClient,
}) => {
  return isCorsicaUser && !isImitatingClient ? (
    <FormSelect
      className="parent-selector"
      searchPlaceholder="Enter company name to search"
      options={dropdownOptions}
      value={selectedCustomer.id}
      isLoading={isLoading}
      onChange={(e, data) => {
        handleCustomerDropdownChange(e, data?.value);
      }}
      scrollIntoView={true}
      selectionFormatter={opt => (
        <span className="customerName">
          {opt.text || ''}
          <span className="region">
            {selectedCustomer?.region && !isLoading
              ? ` (${selectedCustomer?.region})`
              : ''}
          </span>
        </span>
      )}
    />
  ) : (
    <span className="customerName">{selectedCustomer?.name || null}</span>
  );
};
