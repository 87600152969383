import React from 'react';

import { Button, Modal } from 'semantic-ui-react';

import './ScheduleHelpButton.scss';
import scheduleHelpUserWhitelist from './ScheduleHelpUsers';
import scheduleHelpClientWhitelist from './ScheduleHelpClients';
const urlToLoad =
  'https://outlook.office365.com/owa/calendar/CorsicaCafe@corsicatech.com/bookings/';

function checkScheduleHelpUsers(userId, customerId, imitateClientId) {
  // imitate client regardless of other permissions
  if (imitateClientId) {
    return scheduleHelpClientWhitelist.includes(imitateClientId);
  }
  return (
    scheduleHelpClientWhitelist.includes(customerId) ||
    scheduleHelpUserWhitelist.includes(userId)
  );
}

export default function ScheduleHelpButton({
  userId,
  customerId,
  imitateClientId,
  ...otherProps
}) {
  const [open, setOpen] = React.useState(false);
  return checkScheduleHelpUsers(userId, customerId, imitateClientId) ? (
    <Modal
      className="ScheduleHelpButton"
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={
        <Button primary inverted {...otherProps}>
          {'Schedule Help'}
        </Button>
      }>
      <Modal.Content>
        <iframe title="Schedule Help" src={urlToLoad}></iframe>
      </Modal.Content>
    </Modal>
  ) : null;
}
